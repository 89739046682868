<template>
    <div class="h-full bg-gray-50">
        <!-- Screenshot showcase container with centered content -->
        <div class="h-full w-full flex flex-col items-center justify-center p-8">
            <!-- Screenshot carousel container -->
            <div class="relative w-full max-w-2xl">
                <!-- Animation container -->
                <div class="relative h-[700px]">
                    <!-- Screenshot 1 -->
                    <div
                        class="absolute top-0 left-0 w-full transform transition-all duration-1000 ease-in-out"
                        :class="activeScreenshot === 0 ? 'opacity-100 z-10' : 'opacity-0 z-0'"
                    >
                        <div class="overflow-hidden transform transition-all duration-1000 ease-in-out"
                             :class="activeScreenshot === 0 ? 'translate-y-0 opacity-100' : '-translate-y-24 opacity-0'">
                            <img
                                :src="'/img/dropzone-2.png'"
                                alt="Nomad Scribe dashboard screenshot"
                                class="w-full h-auto"
                            />
                        </div>

                        <!-- Testimonial card overlapping the screenshot -->
                        <div class="bg-white rounded-lg shadow-lg p-7 max-w-md relative -mt-16 ml-8 border border-gray-100 transform transition-all duration-1000 ease-in-out"
                             :class="activeScreenshot === 0 ? 'translate-y-0 opacity-100 delay-400' : 'translate-y-24 opacity-0'">

                            <p class="text-gray-600 text-base font-light leading-relaxed mt-3 font-merriweather">
                                "Nomad Scribe captures everything in real-time with remarkable accuracy. My notes are more thorough, yet I'm finishing my workday on time."
                            </p>
<!--                            <div class="flex items-start mb-3">-->
<!--                                <div class="bg-blue-100 rounded-full h-10 w-10 flex items-center justify-center mr-4">-->
<!--                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <div class="text-gray-900 font-semibold text-lg">Dr. Juan Castillo Calcáneo</div>-->
<!--                                    <div class="text-gray-500 text-xs">Neurosurgeon, Centro Médico ABC</div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>

                    <!-- Screenshot 2 -->
                    <div
                        class="absolute top-0 left-0 w-full transform transition-all duration-1000 ease-in-out"
                        :class="activeScreenshot === 1 ? 'opacity-100 z-10' : 'opacity-0 z-0'"
                    >
                        <div class="overflow-hidden transform transition-all duration-1000 ease-in-out"
                             :class="activeScreenshot === 1 ? 'translate-y-0 opacity-100' : '-translate-y-24 opacity-0'">
                            <img
                                :src="'/img/scribe-processing.png'"
                                alt="Nomad Scribe EHR integration screenshot"
                                class="w-full h-auto"
                            />
                        </div>

                        <!-- Stats card overlapping the screenshot -->
                        <div class="bg-white rounded-lg shadow-lg p-7 max-w-md relative -mt-16 ml-auto mr-8 border border-gray-100 transform transition-all duration-1000 ease-in-out"
                             :class="activeScreenshot === 1 ? 'translate-y-0 opacity-100 delay-400' : 'translate-y-24 opacity-0'">
                            <h3 class="text-xl font-bold mb-5 text-gray-800 font-merriweather">Save time, increase patient satisfaction</h3>
                            <div class="grid grid-cols-2 gap-5">
                                <div class="text-center p-4 bg-green-50 rounded-lg">
                                    <div class="font-bold text-3xl text-green-600 mb-1 font-merriweather">1-3h</div>
                                    <div class="text-gray-700 text-sm font-medium">Daily time savings per doctor</div>
                                </div>
                                <div class="text-center p-4 bg-blue-50 rounded-lg">
                                    <div class="font-bold text-3xl text-blue-600 mb-1 font-merriweather">50%+</div>
                                    <div class="text-gray-700 text-sm font-medium">Reduction in errors in medical notes</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Screenshot 3 -->
                    <div
                        class="absolute top-0 left-0 w-full transform transition-all duration-1000 ease-in-out"
                        :class="activeScreenshot === 2 ? 'opacity-100 z-10' : 'opacity-0 z-0'"
                    >
                        <div class="overflow-hidden transform transition-all duration-1000 ease-in-out"
                             :class="activeScreenshot === 2 ? 'translate-y-0 opacity-100' : '-translate-y-24 opacity-0'">
                            <img
                                :src="'/img/notes.png'"
                                alt="Nomad Scribe mobile app screenshot"
                                class="w-full h-auto"
                            />
                        </div>

                        <!-- Integration info card -->
                        <div class="bg-white rounded-lg shadow-lg p-7 max-w-md relative -mt-16 mx-auto border border-gray-100 transform transition-all duration-1000 ease-in-out"
                             :class="activeScreenshot === 2 ? 'translate-y-0 opacity-100 delay-400' : 'translate-y-24 opacity-0'">
                            <h3 class="text-xl font-bold mb-3 text-gray-800 font-merriweather">Generate The Documents You Need</h3>
                            <p class="text-gray-600 text-base leading-relaxed mb-5">
                                No more hassle remembering details after patient visits and spending time writing documents.
                            </p>
                            <div class="flex justify-center text-sm space-x-4">
                                <div class="w-56 h-12 bg-gray-100 rounded-lg flex items-center justify-center">
                                    <span class="font-medium text-gray-700">SOAP Notes</span>
                                </div>
                                <div class="w-56 h-12 bg-gray-100 rounded-lg flex items-center justify-center">
                                    <span class="font-medium text-gray-700">Preauthorizations</span>
                                </div>
                                <div class="w-56 h-12 bg-gray-100 rounded-lg flex items-center justify-center">
                                    <span class="font-medium text-gray-700">Referral Letters</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

// Track active screenshot (0-indexed)
const activeScreenshot = ref(0);
let autoplayInterval = null;

// Set active screenshot with a method for both auto and manual navigation
const setActiveScreenshot = (index) => {
    activeScreenshot.value = index;
};

// Auto-advance screenshots
const startAutoplay = () => {
    autoplayInterval = setInterval(() => {
        activeScreenshot.value = (activeScreenshot.value + 1) % 3;
    }, 5000); // Change screenshot every 5 seconds
};

// Manually navigate to a specific screenshot
const goToScreenshot = (index) => {
    setActiveScreenshot(index);

    // Reset autoplay timer when manually navigating
    if (autoplayInterval) {
        clearInterval(autoplayInterval);
        startAutoplay();
    }
};

// Start with screenshot 1, then begin autoplay
onMounted(() => {
    activeScreenshot.value = 0;
    startAutoplay();
});

// Clean up interval when component is destroyed
onBeforeUnmount(() => {
    if (autoplayInterval) {
        clearInterval(autoplayInterval);
    }
});
</script>