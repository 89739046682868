<template>
    <div class="flex flex-col h-full bg-gradient-to-br from-indigo-50 via-white to-purple-50 p-12 md:p-20 relative">
        <!-- Logo at top -->
        <div class="relative z-10">
            <NewLogoComponent/>
        </div>

        <!-- Centered main content -->
        <div class="flex-1 flex flex-col justify-center items-center relative z-10">
            <div class="w-full max-w-lg">
<!--                <div class="inline-block px-4 py-1.5 bg-white/80 backdrop-blur-sm border border-gray-200/50 shadow-sm text-indigo-700 rounded-full text-xs font-medium mb-6 tracking-wide">-->
<!--                    Available for Early Access-->
<!--                </div>-->
                <div class="mb-24">
                    <h1 class="text-5xl md:text-6xl font-semibold mb-8 text-gray-900 leading-snug font-merriweather">
                        {{ greeting }}
                    </h1>
                </div>

<!--                <h1 class="text-5xl md:text-6xl font-bold mb-8 text-gray-900 leading-snug">-->
<!--                    Save time with notes,<br />-->
<!--                    focus on <span class="text-indigo-600">your patients</span>-->
<!--                </h1>-->
<!--                <p class="text-lg text-gray-700 mb-10 leading-relaxed">-->
<!--                    The most accurate medical scribe app, focused on helping doctors save time and improve patient interactions.-->
<!--                </p>-->

                <div class="bg-white/80 backdrop-blur-lg rounded-xl shadow-lg border border-white/60 p-10 mb-12">
                    <Transition name="fade" mode="out-in">
                        <LoginForm
                            v-if="authState === 'login'"
                            @switch-to-signup="authState = 'signup'"
                            @switch-to-reset="authState = 'reset'"
                        />
                        <SignupForm
                            v-else-if="authState === 'signup'"
                            @switch-to-login="authState = 'login'"
                        />
                        <ResetPasswordForm
                            v-else-if="authState === 'reset'"
                            @switch-to-login="authState = 'login'"
                        />
                    </Transition>
                </div>
            </div>
        </div>

        <!-- Learn more button at bottom -->
<!--        <div class="relative z-10">-->
<!--            <button class="group flex items-center px-6 py-3 rounded-full bg-white/80 backdrop-blur-sm border border-white/60 shadow-sm hover:bg-white/90 hover:shadow-md transition-all duration-200">-->
<!--        <span class="text-sm font-medium bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">-->
<!--            Learn more-->
<!--        </span>-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2 text-indigo-500 group-hover:translate-y-0.5 transition-transform duration-200" viewBox="0 0 20 20" fill="currentColor">-->
<!--                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--            </button>-->
<!--        </div>-->
    </div>
</template>

<script setup>
import { ref } from 'vue'
import LoginForm from '~/components/landing/LoginForm.vue'
import SignupForm from '~/components/landing/SignupForm.vue'
import ResetPasswordForm from '~/components/landing/ResetPasswordForm.vue'
import NewLogoComponent from '~/components/landing/NewLogoComponent.vue'

const authState = ref('login') // Options: 'login', 'signup', 'reset'

const greeting = ref('Welcome!');

onMounted(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
        greeting.value = 'Good morning!';
    } else if (hour < 18) {
        greeting.value = 'Good afternoon!';
    } else {
        greeting.value = 'Good evening!';
    }
});

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease, transform 0.2s ease; /* Faster transition */
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translateY(8px); /* Subtle vertical shift */
}

/* Subtle floating animation for background elements */
@keyframes float {
    0% { transform: translateY(0px) translateX(0px) rotate(0deg); }
    50% { transform: translateY(-15px) translateX(5px) rotate(3deg); }
    100% { transform: translateY(0px) translateX(0px) rotate(0deg); }
}

.animate-float { /* Renamed for clarity */
    animation: float 18s ease-in-out infinite;
}
</style>