<template>
    <div class="w-full">
        <h1 class="text-2xl font-bold mb-6 text-start tracking-tight font-merriweather">Create your account</h1>

        <UForm :schema="schema" :state="formState" @submit="onSubmit" class="space-y-4">
            <!-- Name field -->
            <UFormGroup label="Your Name" name="full_name">
                <UInput
                    v-model="formState.full_name"
                    placeholder="Dr. Jane Smith"
                    icon="i-heroicons-user"
                    size="lg"
                />
            </UFormGroup>

            <!-- Email field -->
            <UFormGroup label="Email" name="email">
                <UInput
                    v-model="formState.email"
                    placeholder="doctor@example.com"
                    icon="i-heroicons-envelope"
                    type="email"
                    size="lg"
                />
            </UFormGroup>

            <!-- Password field -->
            <UFormGroup label="Password" name="password">
                <UInput
                    v-model="formState.password"
                    placeholder="Password"
                    icon="i-heroicons-lock-closed"
                    type="password"
                    size="lg"
                />
            </UFormGroup>

            <!-- Medical provider checkbox -->
            <UFormGroup name="isMedicalProvider">
                <UCheckbox v-model="formState.isMedicalProvider" name="isMedicalProvider">
                    <template #label>
                        <p class="font-normal">I confirm that I am a medical professional</p>
                    </template>
                </UCheckbox>
            </UFormGroup>

            <!-- Submit button -->
            <UButton
                type="submit"
                color="primary"
                block
                size="lg"
                :loading="isSubmitting"
                :disabled="isSubmitting"
            >
                Create Account
            </UButton>

            <!-- Login link -->
            <div class="text-center mt-4">
                <p class="text-sm text-gray-600">
                    Already have an account?
                    <a
                        href="#"
                        class="text-primary hover:underline"
                        @click.prevent="$emit('switch-to-login')"
                    >
                        Log in
                    </a>
                </p>
            </div>
        </UForm>
    </div>
</template>

<script setup lang="ts">
import * as z from 'zod'
import type { FormSubmitEvent } from '#ui/types'

defineEmits(['switch-to-login']);

const { signInWithToken } = useFirebaseAuth()
const accessStore = useAccessStore()

const toast = useToast()
const isSubmitting = ref(false)

// Form schema with medical provider checkbox
const schema = z.object({
    full_name: z.string().min(1, 'Your name is required'),
    email: z.string().email('A valid email is required'),
    password: z.string().min(8, 'Must be at least 8 characters'),
    isMedicalProvider: z.boolean().refine(val => val === true, {
        message: 'You must confirm you are a licensed medical provider'
    })
})

// Form state
const formState = reactive({
    full_name: '',
    email: '',
    password: '',
    isMedicalProvider: false
})

type Schema = z.output<typeof schema>

async function onSubmit(event: FormSubmitEvent<Schema>) {
    try {
        isSubmitting.value = true

        // Add your signup logic here
        console.log('Submitting:', event.data)

        // Define the response type outside the function
        type SignupResult = {
            success: boolean;
            token: string;
            userId: string;
            claims: Record<string, unknown>;
        }

        // @ts-ignore - Type instantiation is excessively deep and possibly infinite
        const result = await $fetch<SignupResult>('/api/v4/auth/signup', {
            method: 'POST',
            body: {
                email: formState.email,
                password: formState.password,
                name: formState.full_name,
                isMedicalProvider: formState.isMedicalProvider
            }
        });
        console.log('Signup result:', result);

        // Perform authentication - this now waits for claims to load
        const user = await signInWithToken(result.token);

        if (!user) {
            throw new Error('Authentication failed')
        }

        toast.add({
            title: 'Account created',
            description: 'Welcome to Nomad Scribe!',
            icon: 'i-heroicons-check-circle',
            color: 'green'
        })

        // Log access store state for debugging
        console.log('Access store after auth:', {
            isAdmin: accessStore.isAdmin,
            isPartner: accessStore.isPartner,
            isClient: accessStore.isClient,
            adminId: accessStore.adminId,
            partnerId: accessStore.partnerId,
            accountId: accessStore.accountId
        })

        // Determine where to route the user based on their access
        if (accessStore.isAdmin) {
            console.log('Admin user detected, redirecting to admin dashboard')
            await navigateTo('/admin')
        } else if (accessStore.isPartner) {
            console.log('Partner user detected, redirecting to scribe')
            await navigateTo('/scribe')
        } else if (accessStore.isClient) {
            console.log('Client user detected, redirecting to dashboard')
            await navigateTo('/home')
        } else {
            console.log('Regular user detected, redirecting to home')
            await navigateTo('/')
        }
    } catch (error: any) {
        toast.add({
            title: 'Error',
            description: error.data?.message || 'Failed to create account',
            icon: 'i-heroicons-exclamation-triangle',
            color: 'red'
        })
    } finally {
        isSubmitting.value = false
    }
}
</script>