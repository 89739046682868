<template>
    <div v-if="isLoading" class="flex items-center justify-center h-screen w-full bg-gray-50">
        <!-- Loading state -->
<!--        <div class="flex flex-col items-center space-y-4">-->
<!--            <div class="w-12 h-12 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>-->
<!--            <p class="text-gray-600 font-medium">Loading your workspace...</p>-->
<!--        </div>-->
    </div>
    <div v-else-if="!shouldRedirect" class="flex flex-col">
        <div class="flex flex-row h-screen">
            <div class="flex-1 h-full">
                <LeftSection />
            </div>
            <div class="flex-1 hidden lg:block h-full">
                <RightSection />
            </div>
        </div>
        <FooterComponent/>
    </div>
</template>

<script setup lang="ts">
import LeftSection from '~/components/landing/LeftSection.vue'
import RightSection from '~/components/landing/RightSection.vue'
import FooterComponent from "~/components/landing/FooterComponent.vue"

definePageMeta({
    layout: 'landing'
})

const config = useRuntimeConfig()

const baseUrl = config.public.BASE_URL || 'http://localhost:3000';

useSeoMeta({
    title: () => `Nomad Scribe | Save time on medical notes with AI`,
    description: () =>
        `Nomad Scribe helps doctors reclaim hours every week by streamlining medical note-taking with powerful, easy-to-use AI. Focus more on patient care—leave the documentation to us.`,
    ogTitle: () => `Nomad Scribe | Save time on medical notes with AI`,
    ogDescription: () =>
        `Nomad Scribe helps doctors reclaim hours every week by streamlining medical note-taking with powerful, easy-to-use AI.`,
    ogImage: () => `${baseUrl}/og-image-scribe.png`,
    ogUrl: () => `${baseUrl}`,
});

const router = useRouter()
const accessStore = useAccessStore()

// State tracking
const isLoading = ref(true)
const shouldRedirect = ref(false)
const firebaseAuth = ref(null)
const redirectInProgress = ref(false)

// Initialize Firebase auth safely after component is mounted
onMounted(() => {
    // Now it's safe to get the Firebase auth composable
    firebaseAuth.value = useFirebaseAuth()

    // Watch for changes to partner status and handle redirection
    watch(() => accessStore.isPartner, async (isPartner) => {
        // Only proceed if not already redirecting
        if (isPartner && !redirectInProgress.value) {
            redirectInProgress.value = true
            shouldRedirect.value = true

            // Keep showing the loading indicator during redirect
            isLoading.value = true

            // Navigate programmatically
            try {
                await router.push('/scribe')
            } catch (error) {
                // If navigation fails, reset states
                console.error('Navigation error:', error)
                isLoading.value = false
                shouldRedirect.value = false
                redirectInProgress.value = false
            }
        } else if (!isPartner && firebaseAuth.value?.currentUser.value) {
            // Not a partner but logged in - show landing page
            isLoading.value = false
        }
    }, { immediate: true })

    // Watch for changes to currentUser to determine if we're logged in
    watch(() => firebaseAuth.value?.currentUser.value, (currentUser) => {
        // If we don't have a user, show the landing page
        if (!currentUser) {
            isLoading.value = false
        }
    }, { immediate: true })
})

// Fallback - if nothing happens after 2 seconds, show the landing page
// but only if we're not already redirecting
const timeout = setTimeout(() => {
    if (!redirectInProgress.value) {
        isLoading.value = false
    }
}, 2000)

onUnmounted(() => {
    clearTimeout(timeout)
})
</script>

<style scoped>
/* Ensure this style block exists to help SFC parsing */
</style>