<template>
    <div class="w-full">
        <h1 class="text-2xl font-bold mb-6 text-start tracking-tight font-merriweather">Please sign in below</h1>

        <UForm :schema="schema" :state="formState" @submit="onSubmit" class="space-y-4">
            <!-- Email field -->
            <UFormGroup label="Email" name="email">
                <UInput
                    v-model="formState.email"
                    placeholder="doctor@example.com"
                    icon="i-heroicons-envelope"
                    type="email"
                    size="lg"
                />
            </UFormGroup>

            <!-- Password field with error message in help slot -->
            <UFormGroup label="Password" name="password">
                <UInput
                    v-model="formState.password"
                    placeholder="Password"
                    icon="i-heroicons-lock-closed"
                    type="password"
                    size="lg"
                />
                <template #help>
                    <div v-if="loginError" class="flex items-center text-red-500 text-sm mt-1 gap-1">
                        <UIcon name="i-heroicons-exclamation-circle-solid" class="h-5 w-5" />
                        <p>{{ loginError }}</p>
                    </div>
                </template>
            </UFormGroup>

            <!-- Forgot password link -->
            <div class="flex justify-end items-center">
                <a
                    href="#"
                    class="text-sm text-primary hover:underline"
                    @click.prevent="$emit('switch-to-reset')"
                >
                    Forgot password?
                </a>
            </div>

            <!-- Submit button -->
            <UButton
                type="submit"
                color="primary"
                block
                size="lg"
                :loading="isSubmitting"
                :disabled="isSubmitting"
            >
                Sign In
            </UButton>

            <!-- Sign up link -->
            <div class="text-center mt-4">
                <p class="text-sm text-gray-600">
                    Don't have an account?
                    <a
                        href="#"
                        class="text-primary hover:underline"
                        @click.prevent="$emit('switch-to-signup')"
                    >
                        Create account
                    </a>
                </p>
            </div>
        </UForm>
    </div>
</template>

<script setup lang="ts">
import * as z from 'zod'
import type { FormSubmitEvent } from '#ui/types'

defineEmits(['switch-to-signup', 'switch-to-reset']);

// Import necessary services and composables
const { signInWithEmail } = useFirebaseAuth()
const accessStore = useAccessStore()
const router = useRouter()
const toast = useToast()
const isSubmitting = ref(false)
const loginError = ref<string | null>(null)

const schema = z.object({
    email: z.string().email('Valid email is required'),
    password: z.string().min(1, 'Password is required')
})

// Form state
const formState = reactive({
    email: '',
    password: ''
})

// Clear error when user types in password field
watch(() => formState.password, () => {
    if (loginError.value) {
        loginError.value = null
    }
})

type Schema = z.output<typeof schema>

// Handle authentication and determine appropriate redirect
async function handleAuthentication(email: string, password: string) {
    // Reset any previous error
    loginError.value = null

    try {
        // Perform authentication - this now waits for claims to load
        const user = await signInWithEmail(email, password)

        if (!user) {
            throw new Error('Authentication failed')
        }

        console.log('Authentication successful, user:', user.uid)
    } catch (error) {
        console.error('Authentication error:', error)
        throw error
    }
}

// Form submission handler
async function onSubmit(event: FormSubmitEvent<Schema>) {
    try {
        isSubmitting.value = true

        // Call our authentication handler
        await handleAuthentication(event.data.email, event.data.password)

        toast.add({
            title: 'Welcome back',
            description: 'Successfully signed in',
            icon: 'i-heroicons-check-circle',
            color: 'green'
        })

        // Log access store state for debugging
        console.log('Access store after auth:', {
            isAdmin: accessStore.isAdmin,
            isPartner: accessStore.isPartner,
            isClient: accessStore.isClient,
            adminId: accessStore.adminId,
            partnerId: accessStore.partnerId,
            accountId: accessStore.accountId
        })

        // Determine where to route the user based on their access
        if (accessStore.isPartner) {
            console.log('Partner user detected, redirecting to scribe')
            await navigateTo('/scribe')
        } else {
            console.log('Regular user detected, redirecting to home')
            await navigateTo('/')
        }
    } catch (error: any) {
        console.error('Login error:', error)

        // Set the error message to display in the form
        loginError.value = error.message || 'Invalid email or password'
    } finally {
        isSubmitting.value = false
    }
}
</script>