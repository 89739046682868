<template>
    <div class="w-full">
        <!-- Success state for when email has been sent -->
        <div v-if="resetSuccess">
            <h1 class="text-2xl font-bold mb-6 text-center tracking-tight">Check your email</h1>
            <p class="text-sm text-gray-600 mb-6">
                Thanks! If <span class="font-semibold">{{ submittedEmail }}</span> matches an email we have on file, then we've
                sent you an email containing further instructions for resetting your password.
            </p>
            <p class="text-sm text-gray-600">
                If you haven't received an email in 5 minutes, check your spam,
                <UButton label="try again" @click="resetForm(true)" variant="link" class="px-0 py-0" />, or
                <UButton label="try a different email" @click="resetForm(false)" variant="link" class="px-0 py-0" />.
            </p>

            <!-- Return to login link -->
            <div class="text-center mt-6">
                <UButton
                    variant="outline"
                    class="w-full"
                    @click="$emit('switch-to-login')"
                >
                    Return to sign in
                </UButton>
            </div>
        </div>

        <!-- Form state when email hasn't been sent yet -->
        <div v-else>
            <h1 class="text-2xl font-bold mb-6 text-start tracking-tight font-merriweather">Reset your password</h1>
            <p class="text-sm text-gray-600 mb-6">
                Enter the email address associated with your account and we'll send you a link to reset your password.
            </p>

            <UForm :schema="schema" :state="formState" @submit="onSubmit" class="space-y-4">
                <!-- Email field -->
                <UFormGroup label="Email Address" name="email">
                    <UInput
                        v-model="formState.email"
                        placeholder="doctor@example.com"
                        icon="i-heroicons-envelope"
                        type="email"
                        size="lg"
                        :color="resetError ? 'red' : undefined"
                    />
                    <!-- Error message -->
                    <template v-if="resetError" #help>
                        <div class="flex items-center text-red-500 text-sm mt-1 gap-1">
                            <UIcon name="i-heroicons-exclamation-circle-solid" class="h-5 w-5" />
                            <p>{{ resetError }}</p>
                        </div>
                    </template>
                </UFormGroup>

                <!-- Submit button -->
                <UButton
                    type="submit"
                    color="primary"
                    block
                    size="lg"
                    :loading="isSubmitting"
                    :disabled="isSubmitting"
                >
                    Send Reset Link
                </UButton>

                <!-- Sign in link -->
                <div class="text-center mt-4">
                    <p class="text-sm text-gray-600">
                        <a
                            href="#"
                            class="text-primary hover:underline"
                            @click.prevent="$emit('switch-to-login')"
                        >
                            Return to sign in
                        </a>
                    </p>
                </div>
            </UForm>
        </div>
    </div>
</template>

<script setup lang="ts">
import * as z from 'zod'
import type { FormSubmitEvent } from '#ui/types'

defineEmits(['switch-to-login']);

// Import necessary services and composables
const { sendPasswordReset } = useFirebaseAuth()
const toast = useToast()
const isSubmitting = ref(false)
const resetError = ref<string | null>(null)
const resetSuccess = ref(false)
const submittedEmail = ref('')

const schema = z.object({
    email: z.string().email('Valid email address is required'),
})

// Form state
const formState = reactive({
    email: '',
})

// Clear error when user types in email field
watch(() => formState.email, () => {
    if (resetError.value) {
        resetError.value = null
    }
})

// Reset form to allow trying again
function resetForm(soft?: boolean) {
    resetSuccess.value = false
    formState.email = soft ? formState.email : ''
}

type Schema = z.output<typeof schema>

// Form submission handler
async function onSubmit(event: FormSubmitEvent<Schema>) {
    try {
        isSubmitting.value = true
        resetError.value = null

        // Store the email to display in the success message
        submittedEmail.value = event.data.email

        // Call password reset function from the composable
        const success = await sendPasswordReset(event.data.email)

        if (success) {
            // Show success state
            resetSuccess.value = true

            // Optionally add a toast notification
            toast.add({
                title: 'Email sent',
                description: 'Check your inbox for password reset instructions',
                icon: 'i-heroicons-envelope',
                color: 'green'
            })
        }
    } catch (error: any) {
        console.error('Password reset error:', error)

        // Set error message to display in the form
        resetError.value = error.statusMessage || error.message || 'Failed to send reset email'
    } finally {
        isSubmitting.value = false
    }
}
</script>